import React from 'react';
import UncontrolledCarousel from './UncontrolledCarousel';
import './Presidential.scss';
import pres1 from '../Assets/Presidential/p1.JPG';
import pres2 from '../Assets/Presidential/p2.JPG';
import pres10 from '../Assets/Presidential/ps10.JPG';
import pres9 from '../Assets/Presidential/ps9.JPG';
import pres8 from '../Assets/Presidential/ps8.JPG';
import pres7 from '../Assets/Presidential/ps7.JPG';
import pres6 from '../Assets/Presidential/ps6.JPG';
import './Homepage.scss';

function Presidential() {
    return (
        <div className='home'>
            <section className='d-flex flex-column col-md-9 m-auto align-items-center'>

                <h1><b>Presidental suite</b></h1>
                <p className='my-3'>
                    <b>The Presidential Suite is more than just a place to stay
                    it is a unique experience that will leave you feeling pampered
                    and refreshed.</b> From the moment you arrive, you will be greeted
                    by our dedicated staff who will take care of your every need.
                    Experience our spacious, luxurious, independent Presidential
                    Suite rooms where you would enjoy a picturesque forest view
                    through your private terrace. These rooms are the ideal refuge
                    with your loved ones after a long day where you can take a load
                    off, freshen up and enjoy a moment of serenity in your private space,
                    and room service at your fingertips.
                </p>

            </section>

            <section>
                <div className='d-flex flex-column flex-md-row col-md-9 m-auto my-5 justify-content-around'>
                    <div className='col-12 col-md-5 py-2'>
                        <img src={pres1} alt="presidential-room" />
                    </div>
                    <div className='col-12 col-md-5 py-2'>
                        <img src={pres2} alt="presidential-room" />
                    </div>

                </div>
            </section>

            <section className='d-flex flex-column col-md-9 m-auto align-items-center'>
                <p>
                    As you step into the grandeur of our Presidential Suite, you’ll be greeted by a
                    spacious and elegantly appointed living room area, perfect for entertaining guests
                    or simply relaxing in style equipped with a sofa that may be converted into a bed
                    for a guest. The suite is exquisitely decorated, with fine furnishings and artwork
                    inspired by the local culture that create a warm and inviting atmosphere. The sofas
                    and armchairs invite you to sink in and relax. The suite’s master bedroom is a sanctuary
                    of refined comfort, featuring a king-size bed that promise a restful night’s sleep. Wake
                    up to breathtaking views of the forest or unwind with a book in the cozy sitting area, enveloped
                    in an ambiance of tranquility and serenity.
                </p>

                <p>
                    Indulge your senses in the lavish en-suite bathroom, complete with a jacuzzi and luxurious amenities.
                    The Presidential Suite also offers a dedicated workspace, equipped with a sleek desk and high-speed
                    Wi-Fi, ensuring that business travelers can stay connected and productive in a private and inspiring
                    setting. Whether you are traveling for business or pleasure, the Presidential Suite offers the ultimate
                    in luxury and comfort. With its spacious accommodations, stunning views, and impeccable service, you are
                    sure to have a truly memorable stay.
                </p>
            </section>

            <section className='carousel d-flex flex-column col-md-9 m-auto align-items-center'>
            <UncontrolledCarousel image={[pres10, pres9, pres8, pres7, pres6, pres2, pres1]} className='homepage-carousel col-12 col-md-9 ' />
            
            </section>
        </div>

    );

}

export default Presidential;