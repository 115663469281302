import React from 'react';

import { Link } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import './Header.scss';

function Header() {
    return (
        <header>

            <div className='d-flex flex-column flex-md-row justify-content-between header '>
               <Link to="/" className='text-decoration-none'>
               <h1 className='headerfont'> Herfazy Resort</h1>
               </Link>
               <p> Creating relationships that last</p>

            </div>
        </header>
    );
}

export default Header;