import Carousel from 'react-bootstrap/Carousel';
import './Carousel.scss';

function UncontrolledCarousel(props) {
    return (
        <Carousel className={props.className} interval={null}>
            {props.image?.length > 0 && props.image.map((item, index) => {
                return <Carousel.Item key={index}>
                    <img src={item} alt='Not avaliable' />

                </Carousel.Item>
            })}


        </Carousel>
    );
}

export default UncontrolledCarousel;