import React from "react";
import UncontrolledCarousel from './UncontrolledCarousel';
import semi1 from '../Assets/Semi/semi1.JPG';
import semi2 from '../Assets/Semi/semi2.jpg';
import semi3 from '../Assets/Semi/semi3.JPG';
import semi4 from '../Assets/Semi/semi4.jpg';
import semi5 from '../Assets/Semi/semi5.JPG';
import './Homepage.scss';
function SemiRoom() {
    return (
        <div className="home">
            <section className='d-flex flex-column col-md-9 m-auto align-items-center'>
        
                <h1><b>Semi -Suites</b></h1>
                <p className='my-3'>
                    Welcome to our charming Semi-Suite, where comfort meets affordability without
                    compromising on style. Experience the perfect blend of convenience and coziness
                    in our thoughtfully designed accommodations, creating a space that feels like a
                    home away from home. Whether you’re unwinding after a day of exploration with
                    your loved ones or catching up on your work, this cozy retreat is perfect for
                    relaxation. Refresh yourself with a rejuvenating shower or indulge in the
                    luxury of a relaxing bath, surrounded by fine amenities. We currently have
                    two seperate kinds of Semi-Suite rooms:
                </p>

            </section>

            <section>
                <div className='d-flex flex-column flex-md-row col-md-9 m-auto my-5 justify-content-around'>
                    <div className='col-12 col-md-5 '>
                        <h1 className="py-2"><b>Twin</b></h1>
                        <img src={semi1} alt="semi-suite-room" />
                        <div className="my-4">
                            <p>
                                <b>Step inside your semi-suite twin bedroom and be greeted by a world
                                of comfort and elegance.</b> The two twin beds are dressed in the finest
                                linens, and the plush sofa and armchairs invite you to relax and
                                unwind, perfect if you are travelling with a family member.The room
                                is decorated in warm, inviting tones, and the windows offer stunning
                                views of the garden.
                            </p>
                            <p>
                               <b> Your private balcony is the perfect place to relax and enjoy the breathtaking
                                scenery.</b> Sip a glass of wine with your loved one as you watch the sunset, or
                                simply sit back and listen to the sound of the birds and the wind in the
                                trees.Whether you’re traveling for business or leisure, our Semi-Suite provides
                                the perfect sanctuary for your stay
                            </p>
                            <p>
                                Discover comfort, affordability, and style with our inviting Semi-Suite. Book
                                your reservation today and experience a delightful retreat that exceeds your
                                expectations. Your home away from home awaits!
                            </p>
                        </div>
                    </div>

                    <div className='col-12 col-md-5 '>
                        <h1 className="py-3"><b>King-Size</b></h1>
                        <img src={semi2} alt="semi-suite-room" />
                        <div className="my-4">
                            <p>
                                Our semi-suite king-size rooms give you plenty of space to relax and unwind. This is
                                especially beneficial if you are traveling with a partner. With its luxurious amenities,
                                stunning views, and impeccable service, you’re sure to have a memorable stay. Refresh
                                yourself with a rejuvenating shower or indulge in the luxury of a relaxing bath,
                                surrounded by fine amenities.
                            </p>
                            <p>
                                <b>Our king-size suite is the perfect place to rejuvenate.</b> Offering stunning views of the
                                surrounding forest area, these luxurious rooms provide <b>a great way to enjoy the scenery
                                through the wide balcony and relax after a long day of sightseeing the beautiful and
                                historic city of Gondar.</b>
                            </p>
                            <p>
                                Our suites can offer a truly luxurious and relaxing experience. If you are looking for
                                a way to spoil yourself or celebrate a special occasion with a partner, a king-size
                                suite is the perfect choice.
                            </p>
                        </div>
                    </div>

                </div>
            </section>

            

            <section className='carousel d-flex flex-column col-md-9 m-auto align-items-center'>
                <UncontrolledCarousel image={[semi1, semi2, semi3, semi4, semi5]} className='homepage-carousel col-12 col-md-9 ' />

            </section>
        </div>
    );
}

export default SemiRoom;