import React from 'react';
import './Footer.scss';
import Facebook from '../Assets/fb.svg';
import Instagram from '../Assets/insta.svg';
import Telegram from '../Assets/telegram.svg';

function Footer() {
    return (
        <section className='foot d-flex flex-column flex-md-row align-items-center '>
            <div className='col-12 col-md-9 gap-5 mx-auto'>
                {/*<div>
                    <h1 className='footer-font d-flex justify-content-center'> Herfazy Resort</h1>
                </div>
                */}
                
                <div className='d-flex dlex-row flex-md-row justify-content-center gap-2 py-3'>
                    <a href="https://www.facebook.com/profile.php?id=100064147205707&mibextid=LQQJ4d" target='_blank' rel='noreferrer'>
                        <img src={Facebook} alt='facebook-logo' className='logo ' />
                    </a>
                    {/*<img src={Instagram} alt='instagram-logo' className='logo' />*/}

                    <a href="https://t.me/Herfazy_resort" target='_blank' rel='noreferrer'>
                        <img src={Telegram} alt='instagram-logo' className='logo' />
                    </a>
                </div>

                <div className='footer-font d-flex dlex-row flex-md-row justify-content-center py-3'>
                    <p>Herfazy Resort Limited 2023</p>
                </div>

            </div>

            {/*    
        <div className='d-flex flex-row col-md-8 m-auto align-items-center'>
                <div className='m-0 col-12 col-md-8'>
                    <form>
                        <div>
                            <input type="text" placeholder='Name (Required)' />
                            <input type="email" placeholder='Email(Required)' />
                        </div>
                        <div>
                            <input type="text" placeholder='Message' />
                        </div>
                        <div>
                            <Button as="input" type="button" value="Button" />
                        </div>
                    </form>
                </div>

                <div className='m-0 col-12 col-md-6'>
                    <h1>Let's chat</h1>
                    <p>
                        Questions, comments or requests?
                        Feel free to reach out, we'd love to hear from you.
                    </p>
                    <p>herfazyresort@gmail.com</p>
                </div>
            </div>
    */}



        </section>



    );
}

export default Footer;