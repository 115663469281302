import React from "react";
import UncontrolledCarousel from './UncontrolledCarousel';
import standard1 from '../Assets/Standard/stand1.JPG';
import standard2 from '../Assets/Standard/stand2.JPG';
import standard3 from '../Assets/Standard/stand3.JPG';
import standard4 from '../Assets/Standard/stand4.JPG';
import standard5 from '../Assets/Standard/stand5.JPG';
import standard6 from '../Assets/Standard/stand6.JPG';
import standard7 from '../Assets/Standard/stand7.JPG';
import standard8 from '../Assets/Standard/stand8.JPG';
import standard9 from '../Assets/Standard/stand9.JPG';
import standard10 from '../Assets/Standard/stand10.JPG';
import './Homepage.scss';

function StandardRoom() {
    return (
        <div className="home">
            <section className='d-flex flex-column col-md-9 m-auto align-items-center'>

                <h1><b>Standard Rooms</b></h1>
                <p className='my-3'>

                    Our standard rooms are the perfect choice for travelers who are looking for a comfortable
                    and affordable place to stay. Whether you’re traveling alone, with a partner, or with your
                    family, our standard rooms have something to offer everyone. Indulge in the perfect
                    balance of privacy and togetherness with our unique combination of the Queen Bedroom and
                    Twin Bedroom. These possible adjoined rooms flawlessly integrate comfort, convenience, and 
                    connectivity, creating a memorable experience for families, friends, and larger groups. 
                    The option for privacy is also ensured if desired. Book your stay today and embark on a 
                    journey of shared moments, cherished memories, and unparalleled comfort.
                </p>

            </section>

            <section>
                <div className='d-flex flex-column flex-md-row col-md-9 m-auto my-5 justify-content-around'>
                    <div className='col-12 col-md-5'>
                        <h1 className="py-3"><b>Twin</b></h1>
                        <img src={standard1} alt="sstandard-room" />
                        <div className="py-3">
                            <p>
                           <b> Whether you’re traveling with a friend, family member, or colleague, our twin 
                            standard rooms are the perfect choice for two.</b> The twin beds offer a comfortable 
                            night’s sleep. Enjoy a relaxing shower in our private bathroom, as well as all the 
                            amenities you need to get ready for the day.
                            </p>
                            <p>
                            Your private patio is the perfect place to relax and enjoy the breathtaking scenery. 
                            Stay connected with complimentary high-speed Wi-Fi, catch up on your favorite shows 
                            on the flat-screen TV, or unwind with a cup of tea from the in-room kettle and 
                            complementary tea bags.
                            </p>
                            <p>
                            For added convenience, our rooms are equipped with a well-appointed work desk, 
                            perfect for business travelers looking to stay productive.  If you’re searching for 
                            the perfect accommodation that strikes the perfect balance between style and 
                            functionality, look no further.
                            </p>
                        </div>
                    </div>

                    <div className='col-12 col-md-5'>
                        <h1 className="py-3"><b>Queen-Size</b></h1>
                        <img src={standard2} alt="standard-room" />
                        <div className="py-3">
                            <p>
                            Step into a realm of tranquility as you enter your Queen Size Standard Room. The centerpiece 
                            of your retreat is a lavish queen-size bed, adorned with plush pillows, ensuring a blissful 
                            night’s sleep that will leave you feeling rejuvenated and ready to take on the world the next day.
                            </p>
                            <p>
                            Whether you’re traveling for business or leisure, our Queen Standard Rooms cater to your every 
                            need. Stay productive at the dedicated work desk, complete with a comfortable chair and ample 
                            lighting, or simply indulge in a moment of tranquility.
                            </p>
                            <p>
                            These rooms are decorated in a warm and inviting style, and they are all equipped with modern 
                            amenities to ensure your comfort. You’ll find everything you need to relax and unwind after 
                            a long day of sightseeing, activities or business meetings.
                            </p>
                        </div>
                    </div>

                </div>
            </section>



            <section className='carousel d-flex flex-column col-md-9 m-auto align-items-center'>
                <UncontrolledCarousel image={[standard1, standard2, standard3, standard4, standard5, standard6, standard7, standard8, standard9, standard10]} className='homepage-carousel col-12 col-md-9 ' />

            </section>
        </div>

    );
}

export default StandardRoom;