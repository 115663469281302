import React from 'react';
import UncontrolledCarousel from './UncontrolledCarousel';
import Preroom from '../Assets/Luxury Rooms - Landing page/pr1.JPG';
import Semiroom from '../Assets/Luxury Rooms - Landing page/sr1.JPG';
import Standard from '../Assets/Luxury Rooms - Landing page/ss1.jpg';
import Tick from '../Assets/tickcheck.svg';
import Res1 from '../Assets/Restaurant/IMG_2169.JPG';
import Res2 from '../Assets/Restaurant/IMG_1890.JPG';
import Res3 from '../Assets/Restaurant/IMG_2079.JPG';
import Res4 from '../Assets/Restaurant/IMG_2153.JPG';
import Res5 from '../Assets/Restaurant/IMG_1940.JPG';
import Res6 from '../Assets/Restaurant/IMG_1913.JPG';
import Res7 from '../Assets/Restaurant/IMG_1915.JPG';
import Res8 from '../Assets/Restaurant/IMG_2089.JPG';
import Res9 from '../Assets/Restaurant/IMG_2159.JPG';
import Lp1 from '../Assets/Herfazy - Landing page/lp1.JPG';
import Lp2 from '../Assets/Herfazy - Landing page/lp2.jpg';
import Lp3 from '../Assets/Herfazy - Landing page/lp3.jpg';
import Lp4 from '../Assets/Herfazy - Landing page/lp4.JPG';
import Lp5 from '../Assets/Herfazy - Landing page/lp5.jpg';
import Lp6 from '../Assets/Herfazy - Landing page/lp6.JPG';
import Lp7 from '../Assets/Herfazy - Landing page/lp7.jpg';
import Lp8 from '../Assets/Herfazy - Landing page/lp8.jpg';
import garden from '../Assets/hotel/IMG_1850.JPG';
import G1 from '../Assets/Garden/g1.JPG';
import G2 from '../Assets/Garden/g2.JPG';
import G3 from '../Assets/Garden/g3.JPG';
import G4 from '../Assets/Garden/g4.JPG';
import G5 from '../Assets/Garden/g5.JPG';
import G6 from '../Assets/Garden/g6.JPG';
import G7 from '../Assets/Garden/g7.JPG';
import G8 from '../Assets/Garden/g8.JPG';
import G9 from '../Assets/Garden/g9.JPG';
import G10 from '../Assets/Garden/g10.JPG';
import G11 from '../Assets/Garden/g11.JPG';
import G12 from '../Assets/Garden/g12.JPG';
import G13 from '../Assets/Garden/g13.JPG';
import CC1 from '../Assets/Conference center/cc1.JPG';
import CC2 from '../Assets/Conference center/cc2.JPG';
import CC3 from '../Assets/Conference center/cc3.JPG';
import CC4 from '../Assets/Conference center/cc4.JPG';
import CC5 from '../Assets/Conference center/cc5.JPG';
import CC6 from '../Assets/Conference center/cc6.JPG';
import './Homepage.scss';
import { Link } from 'react-router-dom';


function HomePage() {
    return (
        <div className='home'>
            <section className='d-flex flex-column flex-md-row align-items-md-center justify-content-md-between'>
                <div className='m-0 col-12 col-md-4'>
                    <p className='homepage'>
                        Nestled on a twelve-thousand-square-meter plot on the outskirts
                        of Gondar, Herfazy is a 4-star resort that offers stunning accommodations,
                        two spacious restaurants, a conference center, and picturesque scenery.
                    </p>
                    <p className='homepage'>
                        Our resort is the perfect place to relax and rejuvenate, whether you’re
                        traveling for business or pleasure. With its stunning accommodations,
                        delicious food, and convenient location, Herfazy has something to offer everyone.
                    </p>
                    <p className='homepage'>
                        We have a deep connection to Gondar, a city that radiates with history, culture,
                        and architectural marvels, and a long family history we are happy to share.
                    </p>
                    <p className='homepage'>
                        <b>
                            Welcome to our home.
                        </b>
                    </p>
                </div>
                <UncontrolledCarousel image={[Lp1, Lp2, Lp3, Lp4, Lp5, Lp6, Lp7, Lp8]} className='homepage-carousel col-12 col-md-7 ' />
            </section>

            <section className='luxury-rooms d-flex flex-column col-md-9 m-auto align-items-center'>

                <div className='col-md-12'>

                    <h2 className='lux-head d-flex justify-content-center'><b>Luxury Rooms</b></h2>
                    <div className='rooms-list d-flex flex-column flex-md-row col-md-12 gap-3 justify-content-between '>

                        <Link to="/presidential-room" className='col-12 col-md-4 link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover" href="#">
                        Underline opacity 0 text-reset'>

                            <div className='rooms-link-item'>
                                <h2 className='d-flex flex-column align-items-center py-3'>Presidential</h2>
                                <img src={Preroom} alt="Presidential Room" />
                            </div>
                        </Link>


                        <Link to="/semi-suite" className='col-12 col-md-4 link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover" href="#">
                        Underline opacity 0 text-reset'>

                            <div className='rooms-link-item'>
                                <h2 className='d-flex flex-column align-items-center py-3'>Semi-Suite Room</h2>
                                <img src={Standard} alt="Standard Room" />
                            </div>
                        </Link>


                        <Link to="/standard-room" className='col-12 col-md-4 link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover" href="#">
                                Underline opacity 0 text-reset'>

                            <div className='rooms-link-item'>
                                <h2 className='d-flex flex-column align-items-center py-3'>Standard Room</h2>
                                <img src={Semiroom} alt="Semi-Suit-Room" />
                            </div>
                        </Link>

                    </div>
                </div>



                <div className='luxury-rooms accomodations-list d-flex flex-column flex-md-row col-12 gap-3 my-5'>

                    <div className='col-12 col-md-4'>
                        <h3 className='interbold'><b>Local & Traditional Restaurant</b></h3>
                        <p>
                            A selection of all the traditional food
                            that we love at home, with cooks of over
                            10 years of experience. Open kitchen policy
                            in both restaurants offers our customers
                            to witness the fresh vegetables,
                            spices, meat selection our chefs use.


                        </p>
                        <p><b>Capacity</b>: 200 people</p>
                        <div className='col-12 col-md-12'>
                            <UncontrolledCarousel image={[Res1, Res2, Res3, Res4, Res5, Res6, Res8, Res9]} />
                        </div>
                    </div>

                    <div className='col-12 col-md-4'>
                        <h3 className='interbold'><b>Conference Center</b></h3>
                        <p>
                            Enjoy local and traditional cuisine crafted by our
                            chefs with of over 10 years of experience. We embrace
                            an open kitchen policy in both our restaurants, inviting
                            our esteemed customers to witness the culinary process
                        </p>
                        <p><b>Capacity</b>: 1000 people</p>
                        <div className='col-12 col-md-12'>
                            <UncontrolledCarousel image={[CC1, CC2, CC3, CC4, CC5, CC6]} />
                        </div>

                    </div>
                    <div className='col-12 col-md-4'>
                        <h3 className='interbold'><b>Garden Scenery</b></h3>
                        <p>
                            Our garden is a tranquil oasis at the entrance of Herfazy.
                            We have a wide variety of plants and flowers from all over
                            Ethiopia. Whether you’re looking for a romantic stroll, a
                            family picnic, or a quiet place to relax and read a book
                        </p>
                        <p><b>Capacity</b>: Unlimited</p>
                        <div className='col-12 col-md-12'>
                            <UncontrolledCarousel image={[G1, G2, G3, G4, G5, G6, G8, G9, G10, G11, G12, G13]} />
                        </div>

                    </div>
                </div>

            </section>




            <section className='garden-top-margin d-flex flex-column flex-md-row col-12 col-md-9 gap-5 mx-auto '>
                <div className='col-12 col-md-6'>
                    <img src={garden} alt='Garden' />
                </div>
                <div className='col-12 col-md-5'>


                    <div>
                        <img src={Tick} alt='Tick' style={{ height: "35px", width: "35px" }} />
                        <p><b>Family Friendly</b></p>
                        <p>
                            We offer a variety of amenities and activities for
                            guests of all ages, including a kids’ playgrounds
                            and garden scenery. This makes it a great destination
                            for families with children of all ages.
                        </p>
                    </div>

                    <div>
                        <img src={Tick} alt='Tick' style={{ height: "35px", width: "35px" }} />
                        <p><b>Luxury Services at Affordable Pricing</b></p>
                        <p>
                            We offers all-inclusive packages that include
                            accommodations, meals, drinks, touring, and more.
                        </p>
                    </div>

                    <div>
                        <img src={Tick} alt='Tick' style={{ height: "35px", width: "35px" }} />
                        <p><b>Romantic Getaway</b></p>
                        <p>
                            Herfazy offers luxurious accommodations,
                            romantic dining options, and other amenities
                            that are perfect for couples looking for a romantic getaway.
                        </p>
                    </div>
                </div>
            </section>


            <section className='col-12 col-md-9 gap-5 mx-auto my-5'>
                <div className='col-12 col-md-9 my-5'>

                    <h1><b>Contact us</b></h1>
                    <p>
                        We are ideally located within a 20-minute drive from
                        the airport, 1.3 km away from Fasilidas Bath and 8 mins
                        from the center town, while offering tranquil views of
                        the luscious countryside with facilities ready to accommodate
                        your needs.
                    </p>
                </div>

                <div className='d-flex flex-column flex-md-row gap-5 col-12 col-md-9 my-4'>
                    <div className='col-12 col-md-5'>

                        <p><b>Address</b>:</p>
                        <p>At the First Curve After the Exit To Goha Hotel, Gonder, Ethiopia</p>
                        <p><b>Telephone</b>:</p>
                        <p>+251988020202</p>
                        <p><b>Email</b>:</p>
                        <p>info@herfazyresort.com</p>
                    </div>

                    <div className='col-12 col-md-10'>

                        {<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3893.2350734402758!2d37.46815447644793!3d12.632577787652604!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164327e2df49b821%3A0x7ff8fb3ffd2daafb!2sHerfazy%20Hotel!5e0!3m2!1sen!2sus!4v1696847586027!5m2!1sen!2sus" style={{ border: 0, width: '100%', height: '100%' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>}
                    </div>
                </div>
            </section>

        </div>

    );
}
export default HomePage