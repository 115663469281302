import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Header from './Components/Header';
import HomePage from './Components/HomePage';
import Footer from './Components/Footer';
import Presidental from './Components/Presidential';
import SemiSuite from './Components/SemiRoom';
import StandardRoom from './Components/StandardRoom';

function App() {
  return (
    <BrowserRouter>
      <div className="App">

        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/presidential-room" element={<Presidental />} />
          <Route path="/semi-suite" element={<SemiSuite />} />
          <Route path="/standard-room" element={<StandardRoom />} />
        </Routes>

        <Footer />

      </div>
    </BrowserRouter>
  );
}

export default App;
